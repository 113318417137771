import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

export interface GraphqlProfilePics {
  [key: string]: string;
}

const useAirtablePractitionersProfilePicture = () => {
  const allAirtableProfilePics = useStaticQuery(
    graphql`
      query allAirtableProfilePicsQuery {
        allAirtable {
          edges {
            node {
              data {
                Photo_profil {
                  localFiles {
                    childImageSharp {
                      fixed(width: 150) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
                Client_ID
              }
            }
          }
        }
      }
    `,
  );

  return React.useMemo(() => {
    const graphqlProfilePics: GraphqlProfilePics = {};

    allAirtableProfilePics.allAirtable.edges.forEach((edge) => {
      const graphqlFileMakerUserId = edge.node.data.Client_ID;
      if (
        edge.node.data.Photo_profil &&
        edge.node.data.Photo_profil.localFiles[0].childImageSharp &&
        graphqlFileMakerUserId
      ) {
        const childImageSharpFixed =
          edge.node.data.Photo_profil.localFiles[0].childImageSharp.fixed;
        graphqlProfilePics[graphqlFileMakerUserId] = childImageSharpFixed;
      }
    });

    return graphqlProfilePics;
  }, [allAirtableProfilePics]);
};

export default useAirtablePractitionersProfilePicture;
