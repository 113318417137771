import { MapMarker } from '@/components/map';
import MapPopup from '@/components/map/popup';
import List from '@/components/search/list';
import PraticienSearchResult from '@/components/practitioners/list-item';
import useAirtablePractitionersProfilePicture from '@/hooks/use-airtable-practitioners-profile-picture';
import { useCities } from '@/store/hooks/cities';
import { usePractitioners } from '@/store/hooks/practitioners';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import PractitionersSearchSection from '@/components/practitioners/search-section';
import Seo from '@/components/seo';
import Layout from '../components/layout';

export interface GraphqlProfilePics {
  [key: string]: string;
}

const TheraEtPro = () => {
  const graphqLProfilePics = useAirtablePractitionersProfilePicture();

  const { all: allPractitioners } = usePractitioners();

  const {
    actions: { setCurrentSuggestion: setCurrentCitySuggestion },
  } = useCities();

  const listItems = React.useMemo(
    () =>
      allPractitioners.map((practitioner, index) => (
        <div id={`practitioner-${index}`} key={practitioner._id}>
          <PraticienSearchResult
            profilePicture={
              graphqLProfilePics[practitioner.meta.fileMakerUserId]
            }
            practitioner={practitioner}
            index={index}
          />
        </div>
      )),
    [allPractitioners],
  );

  const mapMarkers = React.useMemo(
    (): MapMarker[] =>
      allPractitioners.map((practitioner, index) => ({
        text: (
          <MapPopup
            key={practitioner._id}
            title={practitioner.title}
            subTitle={practitioner.category.title}
            listItemHref={`#practitioner-${index}`}
            link={{
              to: `${practitioner.meta.websiteSlug}#content`,
              content: (
                <>
                  <span>Profil complet</span>
                  <span className="icon">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </>
              ),
            }}
          />
        ),
        position: [practitioner.location.lat, practitioner.location.lon],
      })),
    [allPractitioners],
  );

  return (
    <Layout>
      <Seo title="Annuaire de thérapeutes et professionnels du bien-être" />
      <PractitionersSearchSection />
      <List
        listItems={listItems}
        noResult={{
          message: `Aucun thérapeute trouvé`,
          button: {
            label: `Essayez sur toute la belgique`,
            onClick: () => setCurrentCitySuggestion(``),
          },
        }}
        mapMarkers={mapMarkers}
      />
    </Layout>
  );
};

export default TheraEtPro;
