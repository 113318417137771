import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faLocationArrow,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useFocus } from '@/store/hooks/focus';
import { Practitioner } from '../../../../backend/src/models/practitioners.model';

interface PraticienSearchResultProps {
  practitioner: Practitioner;
  profilePicture?: any;
  // focusFunctions: [number, React.Dispatch<React.SetStateAction<number>>];
  index?: number;
}

const PraticienSearchResult: React.FunctionComponent<
  PraticienSearchResultProps
> = ({ practitioner, profilePicture, index = 0 }) => {
  const {
    actions: { setFocus },
  } = useFocus();
  return (
    // const [focus, setFocus] = focusFunctions;
    <div className={classNames(`box praticien mb-3`)}>
      <div className="columns is-vcentered is-mobile">
        <div className="column is-narrow">
          <figure className="image is-128x128" style={{ overflow: `hidden` }}>
            {profilePicture ? (
              <img src={profilePicture.src} alt="" />
            ) : (
              <StaticImage
                src="../../assets/images/person-placeholder-mini.png"
                alt=""
              />
            )}
          </figure>
        </div>
        <div className="column">
          <h2 className="title is-5 is-size-6-mobile">
            <Link to={`${practitioner.meta.websiteSlug}#content`}>
              <span
                dangerouslySetInnerHTML={{
                  __html: practitioner.title,
                }}
              />
            </Link>
          </h2>
          <p
            className="subtitle is-6 is-size-7-mobile"
            dangerouslySetInnerHTML={{
              __html: practitioner.category.title,
            }}
          />
        </div>
      </div>
      {practitioner.content.description && (
        <p
          className="has-text-left mb-5 px-1"
          dangerouslySetInnerHTML={{
            __html: practitioner.content.description,
          }}
        />
      )}
      {(practitioner.location.address || practitioner.location.city) && (
        <p className="mb-5 icon-text">
          <span className="icon has-text-primary">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </span>
          <span>
            {practitioner.location.address},&nbsp;
            <span
              dangerouslySetInnerHTML={{
                __html: practitioner.location.city,
              }}
            />
          </span>
        </p>
      )}
      {practitioner.content.accompanimentsDomains && (
        <div className="tags">
          {practitioner.content.accompanimentsDomains.map((domain) => (
            <span className="tag is-primary is-light" key={domain}>
              {domain}
            </span>
          ))}
        </div>
      )}
      <p className="has-text-right">
        <button
          type="button"
          className="button is-small is-inverted is-primary has-text-weight-bold mr-3 is-hidden-mobile"
          onClick={() => {
            setFocus(index);
          }}
        >
          <span>Localiser</span>
          <span className="icon">
            <FontAwesomeIcon icon={faLocationArrow} />
          </span>
        </button>
        <Link
          to={`${practitioner.meta.websiteSlug}#content`}
          className="button is-primary is-small has-text-weight-bold"
        >
          <span>Profil complet</span>
          <span className="icon">
            <FontAwesomeIcon icon={faArrowRight} />
          </span>
        </Link>
      </p>
    </div>
  );
};
export default PraticienSearchResult;
